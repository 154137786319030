<template>
     <div class="module-preview-wrapper">
        <div class="module-preview--header">footer</div>
        <div class="module-preview--contents">
            <div>
                <img :src="getValue('logo')" class="h-8 inline mb-2" v-if="getValue('logo')" />
            </div>   
            <div>
                <ul class="flex items-center justify-end">
                    <li v-if="getValue('facebookLink')"><icon name="facebook" class="w-4 h-4"/></li>
                    <li class="ml-4" v-if="getValue('twitterLink')"><icon name="twitter" class="w-4 h-4"/></li>
                    <li class="ml-4" v-if="getValue('instagramLink')"><icon name="instagram" class="w-4 h-4"/></li>
                </ul>
            </div>
        </div>
    </div>          
</template>

<script>
import Icon from "@/components/ui/Icon";
import Widget from "@/mixins/Widget";

export default {
    components: { Icon },
    mixins: [ Widget ],
}
</script>
<style scoped>   
    .module-preview--contents{
        grid-template-columns: 1.5fr 1fr;
    } 

</style>